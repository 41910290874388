/** @jsx jsx this comment is required for Storybook to render */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { breakpoints, unitConverter as uc } from '../../../styles/base';

export const convertSpacingValue = input => {
  switch (typeof input) {
    case 'string':
      return input;
    default:
      return uc(`${input}px`);
  }
};

const getMarginKey = directionKey =>
  `margin${directionKey.replace(/^\S/g, match => match.toUpperCase())}`;

export const buildResponsiveMap = (directionKey, config) => {
  const map = {};
  const keys = Object.keys(config);
  keys.forEach(key => {
    const value = config[key];
    if (key === 'default' || key === 'largeDesktop') {
      map[getMarginKey(directionKey)] = convertSpacingValue(value);
    } else {
      map[`@media(${breakpoints[key]})`] = {
        [getMarginKey(directionKey)]: convertSpacingValue(value),
      };
    }
  });
  return map;
};

export const addDirectionStyles = (directionKey, config, target) => {
  if (!config) return target;
  const result = Object.assign({}, target);

  let modifiedCss = {};

  switch (typeof config) {
    case 'number':
      modifiedCss[getMarginKey(directionKey)] = uc(`${config}px`);
      break;
    case 'string':
      modifiedCss[getMarginKey(directionKey)] = config;
      break;
    default:
      modifiedCss = buildResponsiveMap(directionKey, config);
      break;
  }

  const keys = Object.keys(modifiedCss);

  keys.forEach(key => {
    const newCss = modifiedCss[key];
    if (result[key]) {
      result[key] = Object.assign(result[key], newCss);
    } else {
      result[key] = newCss;
    }
  });

  return result;
};

const responsivePropType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
  PropTypes.shape({
    default: PropTypes.string,
    largeDesktop: PropTypes.string,
    desktop: PropTypes.string,
    smallDesktopMax: PropTypes.string,
    largeTablet: PropTypes.string,
    tablet: PropTypes.string,
    mobile: PropTypes.string,
  }),
]);

const SectionSpacer = ({ bottom, children, className, top }) => {
  let sectionSpacerCss = {};
  sectionSpacerCss = addDirectionStyles('top', top, sectionSpacerCss);
  sectionSpacerCss = addDirectionStyles('bottom', bottom, sectionSpacerCss);
  return (
    <div css={sectionSpacerCss} className={className}>
      {children}
    </div>
  );
};

SectionSpacer.propTypes = {
  top: responsivePropType,
  bottom: responsivePropType,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

SectionSpacer.defaultProps = {
  className: '',
  top: null,
  bottom: null,
};

export default SectionSpacer;
