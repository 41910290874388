import PropTypes from 'prop-types';
import React from 'react';
import { css } from '@emotion/core';
import { graphql } from 'gatsby';
import Layout from '../../organisms/Layout/Layout';
import ContentCourseCatalogSection from '../../organisms/ContentCourseCatalogSection/ContentCourseCatalogSection';
import BubbleCtaSection from '../../organisms/BubbleCtaSection/BubbleCtaSection';
import sanityImage from '../../../utils/sanityImage';
import {
  breakpoints,
  colors,
  fontSizes,
  unitConverter as uc,
} from '../../../styles/base';
import CarouselCard from '../../molecules/CarouselCard/CarouselCard';
import { Heading1 } from '../../atoms/Headings/Headings';
import ResponsiveContentWrapper from '../../organisms/ResponsiveContentWrapper/ResponsiveContentWrapper';
import SectionSpacer from '../../molecules/SectionSpacer/SectionSpacer';
import useLocalize from '../../../hooks/useLocalize';

const cccPage = ({
  data: rawData,
  location: { href },
  pageContext: { locale },
}) => {
  // unpublished pages return null data
  if (!rawData || rawData.sanityCccPage === null) {
    return null;
  }

  const data = useLocalize(rawData, locale);

  const cccPageProps = {
    metadata: { ...data.sanityCccPage._rawRoute, href },
    backgroundImage: data.sanityCccPage._rawBackgroundImage,
    carouselCard: data.sanityCccPage._rawCarouselCard,
    catalogCtaText: data.sanityCccPage._catalogCtaText,
    animatedButton: data.sanityCccPage._rawAnimatedButton,
    bubbleCtaSection: data.sanityCccPage._rawBubbleCtaSection,
  };

  const backgroundCss = css`
    overflow: hidden;
    background-image: url(${sanityImage(cccPageProps.backgroundImage)
      .auto('format')
      .url()});
    background-repeat: no-repeat;
    background-size: cover;

    @media (${breakpoints.tablet}) {
      background-position: center;
    }
  `;
  const heading1Css = css`
    margin-top: ${uc('200px')};
    color: ${colors.white};
    font-size: ${fontSizes.thirtyEight};
    text-align: center;

    @media (${breakpoints.mobile}) {
      font-size: ${fontSizes.twentyEight};
    }
  `;
  // width of these two elements is hard coded in CarouselCard
  // 20px margin on bottom creates room for cards shadow on the bottom
  const cccPageHeaderCardCss = css`
    width: 100%;

    .carousel-card {
      width: 100%;
    }
  `;

  // this fixes fixed filters menu overlapping footer.
  const cccLayoutCss = css`
    footer {
      z-index: 0;
    }
  `;

  return (
    <Layout
      locale={locale}
      menuColor="white"
      sanityId={data.sanityCccPage._id}
      {...cccPageProps.metadata}
      css={cccLayoutCss}
    >
      <div key="ccc-wrapper" css={backgroundCss}>
        <ResponsiveContentWrapper>
          <SectionSpacer bottom={{ default: 50, tablet: 32 }}>
            <Heading1 css={heading1Css}>
              Increase talent development with binge-worthy content
            </Heading1>
            <CarouselCard
              {...cccPageProps.carouselCard}
              css={cccPageHeaderCardCss}
            />
          </SectionSpacer>
        </ResponsiveContentWrapper>
      </div>
      <ResponsiveContentWrapper>
        <SectionSpacer
          top={{ default: 48, tablet: 16 }}
          bottom={{ default: 250, tablet: 100 }}
        >
          <ContentCourseCatalogSection />
        </SectionSpacer>
      </ResponsiveContentWrapper>
      {cccPageProps.bubbleCtaSection && (
        <BubbleCtaSection {...cccPageProps.bubbleCtaSection} />
      )}
    </Layout>
  );
};
cccPage.propTypes = {
  data: PropTypes.shape({}),
  location: PropTypes.shape({ href: PropTypes.string }),
};

cccPage.defaultProps = {
  data: {},
  location: { href: '' },
};

export default cccPage;

export const query = graphql`
  query($slug: String) {
    sanityCccPage(
      route: { enabled: { eq: true }, slug: { current: { eq: $slug } } }
    ) {
      _rawRoute(resolveReferences: { maxDepth: 100 })
      _rawBackgroundImage(resolveReferences: { maxDepth: 100 })
      _rawCarouselCard(resolveReferences: { maxDepth: 100 })
      _rawBubbleCtaSection(resolveReferences: { maxDepth: 100 })
      catalogCtaText: _rawCatalogCtaText(resolveReferences: { maxDepth: 100 })
      _rawAnimatedButton(resolveReferences: { maxDepth: 100 })
      _id
    }
  }
`;
